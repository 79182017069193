@import '../../../../scss/theme-bootstrap';

.hero-block {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  &__content,
  &__content-wrapper,
  &__content-over-media,
  &__media-wrapper {
    display: flex;
    flex-direction: column;
  }
  &__media {
    display: block;
    width: 100%;
    height: auto;
  }
  &__media-wrapper {
    position: relative;
    text-align: center;
  }
  &__content-over-media {
    z-index: 9;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    &.padding-default-content {
      padding: 40px 20px;
      @include breakpoint($landscape-up) {
        padding: 40px;
      }
    }
    .hero-block-no-media & {
      position: relative;
    }
    .playing-video & {
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
  }
  &__content {
    padding: 40px 20px;
    @include breakpoint($landscape-up) {
      padding: 40px;
    }
  }
  &__content-wrapper {
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  &__content-media {
    z-index: -1;
  }
  // Layout Options.
  &--flip-layout {
    display: flex;
    flex-direction: column-reverse;
  }
  &--flip-layout-mobile {
    display: flex;
    flex-direction: column-reverse;
    @include breakpoint($landscape-up) {
      flex-direction: column;
    }
  }
  &--flip-layout-desktop {
    display: flex;
    flex-direction: column;
    @include breakpoint($landscape-up) {
      flex-direction: column-reverse;
    }
  }
  &--force-full-width {
    width: 100%;
    position: relative;
    #{$ldirection}: 50%;
    #{$rdirection}: 50%;
    margin-#{$ldirection}: -50%;
    margin-#{$rdirection}: -50%;
    max-width: none;
  }
  .content-over-media__badge-wrapper,
  .content__badge-wrapper {
    margin-bottom: 10px;
    .content-over-media__badge,
    .content__badge {
      display: inline-block;
      height: 32px;
      .mantle-media-asset {
        width: auto;
        img,
        picture,
        video {
          height: 100%;
          width: auto;
        }
      }
    }
  }
  &__link-wrapper {
    margin-top: 14px;
    width: inherit;
    .content-block__link {
      .link {
        font-size: 17px;
      }
    }
    .button {
      width: 100%;
      max-width: 295px;
      line-height: 1.1;
    }
  }
  &__above-content {
    .hero-block__content {
      padding: 0;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 24px;
      .custom-text {
        margin-bottom: 0;
      }
    }
    .hero-block__cta-beside {
      flex-direction: row;
      justify-content: space-between;
      width: auto;
      flex-wrap: wrap;
      a {
        font-weight: bold;
      }
      .content-block__content {
        flex: 0 0 70%;
      }
      .hero-block__link-wrapper {
        margin-top: 0;
        text-align: $rdirection;
        flex: auto;
      }
      .custom-text {
        font-size: $medium-text-font-size;
        line-height: $medium-text-line-height;
      }
    }
    .hero-block & {
      .content-block__line--header {
        .custom-text {
          font-size: $medium-text-font-size;
          line-height: $medium-text-line-height;
        }
      }
    }
  }
  .content-block__content {
    height: auto;
    flex: 0;
    display: block;
  }
  .content-block__line {
    margin: 0;
    &--small-content {
      margin-top: 40px;
      .mantle-custom-text {
        margin-bottom: 0;
      }
    }
  }
  .content-block__line--header {
    margin: 1px 0;
    .custom-text {
      font-size: 40px;
      line-height: 1;
    }
    &-no-margin {
      .mantle-custom-text {
        margin-bottom: 0;
      }
    }
  }
  .content-block__line--content {
    margin: 0 0 25px;
    @include breakpoint($medium-up) {
      margin: 0 0 30px;
    }
  }
  p {
    margin-bottom: 0;
    line-height: inherit;
  }
  .mantle-custom-text {
    text-align: unset;
    margin-bottom: 20px;
  }
  .headline-6,
  .headline-5,
  .headline-4,
  .headline-3 {
    line-height: 1;
    letter-spacing: 0;
  }
  .body-1,
  .body-2 {
    line-height: 1.1;
    margin: 0;
  }
  .button {
    line-height: 1.3;
    padding: 18px 32px;
    text-transform: none;
    min-width: 100%;
    @include breakpoint($medium-up) {
      min-width: 295px;
    }
    &.cta-primary-white {
      &:hover {
        background-color: $color-gray-on-light;
      }
    }
  }
  .link {
    @include typography-link-text();
    text-transform: none;
  }
  .basic-image {
    max-width: unset;
  }
  // layout option
  &--outlined-layout {
    .hero-block__content-over-media-wrapper,
    .hero-block__content-over-media,
    .hero-block__link-wrapper {
      text-align: $ldirection;
      justify-content: flex-start;
      max-width: 100%;
      align-items: flex-start;
    }
    .hero-block__content-over-media {
      padding: 0;
      z-index: 9;
      &::after {
        content: '';
        width: 1px;
        height: 100%;
        background-color: $color-white;
        display: block;
        margin-top: 15px;
      }
      &::before {
        content: '';
        width: 1px;
        height: auto;
        background-color: $color-white;
        display: block;
        position: absolute;
        bottom: 0;
        #{$rdirection}: 0;
        top: 30px;
      }
    }
    .hero-block__content-over-media-wrapper {
      border-bottom: 1px solid $color-white;
      margin: 10px 30px 30px;
    }
    .content-over-media__badge {
      margin-#{$rdirection}: 5px;
    }
    .content-over-media__badge-wrapper {
      display: flex;
      align-items: flex-end;
      width: 100%;
      height: 80px;
      margin: 10px 0;
      &::after {
        content: '';
        width: auto;
        height: 50%;
        display: inline-flex;
        flex-grow: 1;
        border: 1px solid $color-white;
        border-#{$ldirection}: none;
        border-bottom: none;
      }
    }
    .hero-block__link-wrapper {
      margin-bottom: 0;
    }
  }
  .video-block {
    position: inherit;
  }
  .video-block__play-image {
    position: absolute;
    z-index: 10;
  }
  .video-detail__video-wrapper {
    position: inherit;
  }
  .videojs-video__landing {
    position: inherit;
    z-index: unset;
  }
  .videojs-video {
    position: inherit;
    cursor: pointer;
    &__landing__image img {
      outline-offset: -20px;
      @include breakpoint($landscape-up) {
        outline-offset: -50px;
      }
    }
  }
  .sd-product-spp .spp-product-layout__content & {
    &__content {
      .content-block__line--content {
        .headline-4 {
          @include typography-headline--m();
          font-size: 26px;
          line-height: 1.5;
          @include breakpoint($landscape-up) {
            font-size: 44px;
            line-height: 1.7;
          }
        }
      }
    }
  }
}
